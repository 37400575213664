import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class SettingProvider extends HttpRequest {
  getPromotionCodeSetting () {
    this.setHeader(getAuthToken())
    return this.get('setting/promotion-code')
  }

  updatePromotionCodeSetting (formData) {
    this.setHeader(getAuthToken())
    return this.patch('setting/promotion-code', formData)
  }
}

export default SettingProvider
