<template>
  <v-container
    fluid
    class="bg-white">
    <v-row align="center">
      <v-col cols="auto">
        <h2>VIP Campaign Setting</h2>
      </v-col>
      <v-col
        v-if="!isEdit"
        cols="auto">
        <v-btn
          icon
          small
          color="secondary"
          :loading="loading"
          @click="toggleEdit()">
          <v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="auto">
        <v-switch
          v-model="formData.values.enable"
          label="เปิดการใช้งาน"
          :readonly="switchDisabled" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-switch
          v-model="formData.values.productTagIds.status"
          class="mt-1"
          hide-details
          :readonly="switchDisabled" />
      </v-col>
      <v-col cols="6">
        <v-combobox
          v-model="tagsSelected"
          :items="tagsName"
          prepend-inner-icon="mdi-label"
          :disabled="tagSelectorDisabled"
          append-icon
          outlined
          deletable-chips
          hide-selected
          label="แท็ก"
          multiple
          hide-details
          persistent-hint
          small-chips />
      </v-col>
      <v-col cols="12">
        <v-radio-group
          v-model="formData.values.productTagIds.include"
          label="ประเภทการใช้แท็ก"
          row
          :readonly="switchDisabled">
          <v-radio
            label="ใช้ได้กับ Tag"
            :value="true" />
          <v-radio
            label="ใช้ไม่ได้กับ Tag"
            :value="false" />
        </v-radio-group>
      </v-col>
      <v-col
        v-if="isEdit"
        cols="12">
        <v-btn
          color="primary mr-1"
          :loading="loading"
          @click="onSubmit()">
          Submit
        </v-btn>
        <v-btn
          color="secondary"
          :loading="loading"
          @click="toggleEdit()">
          Cancel
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import SettingProvider from '@/resources/SettingProvider'

const ProductAttributeService = new ProductAttributeProvider()
const SettingService = new SettingProvider()

export default {
  data () {
    return {
      loading: false,
      isEdit: false,
      tags: [],
      tagsSelected: [],
      tmpFormData: {
        id: null,
        name: '',
        values: {
          enable: false,
          productTagIds: {
            status: false,
            include: false,
            values: []
          }
        }
      },
      formData: {
        id: null,
        name: '',
        values: {
          enable: false,
          productTagIds: {
            status: false,
            include: false,
            values: []
          }
        }
      }
    }
  },
  computed: {
    switchDisabled () {
      return !this.isEdit || this.loading
    },
    tagSelectorDisabled () {
      return !this.formData.values.productTagIds.status || !this.isEdit || this.loading
    },
    tagsName () {
      return this.tags.map((tag) => tag.name)
    }
  },
  async created () {
    await this.initTags()
    await this.initPromotionCodeSetting()
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage',
      setSnackbar: 'Components/setSnackbar'
    }),
    async initTags () {
      try {
        this.loading = true
        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999999,
          sortBy: ['id'],
          sortDesc: [true]
        })
        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    async initPromotionCodeSetting () {
      try {
        this.loading = true

        const { data } = await SettingService.getPromotionCodeSetting()
        this.formData = data
        this.tagsSelected = this.formData.values.productTagIds.values.map((val) => {
          const found = this.tags.find((tag) => tag.id === val)
          return found?.name || null
        }).filter((val) => !!val)
      } catch (error) {
        console.error('initPromotionCodeSetting', error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    toggleEdit () {
      this.isEdit = !this.isEdit

      if (this.isEdit) {
        this.tmpFormData = JSON.parse(JSON.stringify(this.formData))
      } else {
        this.formData = JSON.parse(JSON.stringify(this.tmpFormData))
      }
    },
    async onSubmit () {
      try {
        this.loading = true

        this.formData.values.productTagIds.values = this.tagsSelected.map((val) => {
          const found = this.tags.find((tag) => tag.name === val)
          return found?.id || null
        }).filter((val) => !!val)

        await SettingService.updatePromotionCodeSetting(this.formData)
        this.setSnackbar({
          value: true,
          message: 'Update successfully.',
          type: 'success'
        })
        this.isEdit = false
        this.initPromotionCodeSetting()
      } catch (error) {
        console.error('onSubmit', error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
